<i18n>
ru:
  serviceFee: Хочу помочь в развитии сервиса
ua:
  serviceFee: Хочу допомогти у розвитку сервісу
us:
  serviceFee: I want to help develop the service
</i18n>

<template>
  <div
    v-if="appConfig.RestaurantSettingsPreRun.ServiceFeeEnabled"
    class="v-service-collection__wrapper v-mb-sm"
  >
    <input
      id="v-service-collection"
      class="v-input-checkbox"
      type="checkbox"
      v-model="serviceFeeActive"
    />
    <label for="v-service-collection">
      <span class="v-checkbox" />
      <span
        class="v-text-for-checkbox"
        v-html="translate('serviceFee.serviceFee')"
      />
      <common-currency :amount="appConfig.RestaurantSettingsPreRun.ServiceFeePrice" />
    </label>
  </div>
</template>

<script setup lang="ts">
const clientStore = useClientStore()
const appConfig = useAppConfig()

const { translate } = useI18nSanitized()

const serviceFeeActive = computed<boolean>({
  get: () => clientStore.ClientState?.data?.ServiceFee?.Active ?? false,
  set: (value: boolean) => {
    clientStore.addServiceFee(value)
  }
})
</script>
