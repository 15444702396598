<i18n>
ru:
  cart: Корзина
  orderType: Способ получения заказа
  personalInfo: Персональная информация
ua:
  cart: Кошик
  orderType: Спосіб отримання замовлення
  personalInfo: Персональна інформація
us:
  cart: Cart
  orderType: Select delivery type
  personalInfo: Personal information
</i18n>

<template>
  <div class="v-container">
    <div class="v-row">
      <div class="v-col-12">
        <h1
          class="v-title"
          v-html="translate('stage.cart')"
        />
      </div>
      <div class="v-col-12">
        <lazy-cart-messages />
      </div>
      <div class="v-col-12">
        <cart-extra-products />
      </div>
    </div>
  </div>
  <cart-recommended />
  <div class="v-container">
    <div class="v-cart">
      <div class="v-row">
        <div class="v-col-12">
          <cart-gifts-ranged />
        </div>
        <div class="v-col-12 v-text-right">
          <cart-cleanup-button />
        </div>
        <div class="v-col-12">
          <lazy-cart-items-page />
        </div>
        <div class="v-col-12">
          <cart-birthday-gift-select />
        </div>
        <div class="v-col-12">
          <cart-promocode />
        </div>
        <div class="v-col-12">
          <h2
            class="v-cart-title"
            v-html="translate('stage.personalInfo')"
          />
          <div class="v-mb-md">
            <cart-personal-info />
          </div>
        </div>

        <div class="v-col-12 v-col-md-6">
          <h2
            class="v-cart-title"
            v-html="translate('stage.orderType')"
          />
          <div class="v-cart-one-stage-order-type">
            <cart-order-type />
          </div>
          <template v-if="clientStore.courierDelivery">
            <lazy-account-saved-addresses-page :can-redact-address="false" />
            <lazy-maps-autocomplete form="cart" />

            <lazy-cart-additional-info />
          </template>
          <template v-else-if="clientStore.selfService">
            <lazy-cart-self-service-terminals />
          </template>
          <template v-else-if="clientStore.inHall">
            <lazy-cart-in-hall-table-select />
          </template>
          <lazy-cart-delivery-time-page />
        </div>
        <div
          class="v-col-12 v-col-md-6"
          v-show="clientStore.courierDelivery"
        >
          <ClientOnly>
            <lazy-cart-delivery-zones />
            <lazy-maps-address />
          </ClientOnly>
        </div>
        <div
          class="v-col-12 v-col-md-6"
          v-show="clientStore.selfService"
        >
          <ClientOnly>
            <lazy-maps-terminals />
          </ClientOnly>
        </div>

        <div class="v-col-12 v-col-md-8">
          <lazy-cart-total-summary-page />
        </div>

        <div class="v-col-12 v-col-md-4">
          <ClientOnly>
            <cart-pay-method-page />
            <cart-callback />
            <cart-service-fee />
            <cart-priority-service />
            <cart-second-step-button />
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const clientStore = useClientStore()

const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-cart {
  .v-cart-title {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
    margin: 0 0 1rem;
  }
}

.v-cart-one-stage-order-type {
  margin-bottom: 1rem;
}
</style>
